import React from "react";
import axios from 'axios';
import PrendariosForm from './PrendariosForm';
import ConfirmMessage from './ConfirmMessage';
import { URL_BASE } from '../config.js';
import s from './PrendariosBody.module.scss';
import { isValidCharacter } from '../../utils';




class PrendariosBody extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        requested: '',
        sendInfo: false,
        errors: {
            email: '',
            phone: '', 
        },
    }

    handleChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    submitUserInfo = () =>{
        const {first_name, last_name, phone, email, requested } = this.state;

        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
         
        axios.post(`/api/v3/contact/`,
        {   code: "CP-buyer",
            first_name: first_name, 
            last_name: last_name,
            phone: phone,
            email: email,
            requested: (requested) ? requested : null,
        },)
        .then((response)=> {
            this.setState({
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                requested: '',
                sendInfo: true,
            });
        })
        .catch(({ response }) => {
            const errors = {};
            Object.keys(response.data).forEach(field => {
                errors[field] = response.data[field];
            });
            this.setState({
                errors: errors,
            });
        });   
    }

    render(){
        const {first_name, last_name, phone, email, requested,  errors, sendInfo} = this.state;
        return(
            <div className={s.prendariosBody}>
               <div className={s.formCard}>
                   {(sendInfo)
                        ? <ConfirmMessage message={'Un asesor comercial se estará contactando con vos.'}/>
                        : <PrendariosForm first_name={first_name} last_name={last_name} phone={phone} email={email} requested={requested} errors={errors} handleChange={this.handleChange} submitUserInfo={this.submitUserInfo}/>
                    }
               </div>
            </div>
        )
    }
}

export default PrendariosBody
