import React from 'react';
import s from './PrendariosForm.module.scss';
import Input from './Input';
import Button from './Button';


const PrendariosForm = ({  first_name, last_name, phone, email, errors, handleChange, requested, submitUserInfo}) => {

    return (
        <div className={s.formWraper}>
            <h6 className={s.formTitle}>Sumate a nuestra red</h6>
            <Input name='first_name' value={first_name} label='Nombre' onChange={handleChange} style={{width: '90%'}}/>
            <Input name='last_name' value={last_name} label='Apellido'  onChange={handleChange} style={{width: '90%'}}/>
            <Input name='email' label='Email' value={email} className={s.inputLarge} onChange={handleChange} error={errors.email && errors.email[0]} style={{width: '90%'}}/>
            <Input name='phone' label='Celular' type='tel' value={phone} className={s.inputLarge} onChange={handleChange} error={errors.phone && errors.phone[0]} style={{width: '90%'}}/>
            {errors.phone && errors.phone[0] ? null : <p className={s.legalText}>Ej.: 1160001234 o 2216000123 (Cod. de área sin el 0 + Número sin el 15)</p>}
            <Input name='requested' value={requested} label='¿Qué monto estarías buscando? (Opcional)' onChange={handleChange} style={{width: '90%'}}/>
            <div className={s.buttonsContainer}>
               <Button text='Enviar' onClick={submitUserInfo} disabled={(first_name && last_name && phone && email) ? false : true}/>
            </div>
        </div>
    )
};

export default PrendariosForm;
